<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">{{ $t('pages.system.variable.title') }}</h2>
    </div>
    <div class="card" v-if="tabSkeleton">
        <div class="card-body d-flex flex-column p-9">
            <el-skeleton />
        </div>
    </div>
    <div class="card" v-else>
        <div class="card-header">
            <div class="card-title"></div>
            <div class="card-toolbar">
                <button type="button" class="btn btn-sm btn-primary" @click="newRecord">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/arrows/arr009.svg" />
                    </span>
                    {{ $t('pages.system.variable.new') }}
                </button>
            </div>
        </div>
        <div class="card-body d-flex flex-column p-9">
            <div v-for="(variable, variableIndex) in table" :key="variableIndex">
                <div class="d-flex align-items-center mb-2">
                    <h5 class="mb-0">{{ variable.code }}</h5>
                    <div class="ms-3">
                        <el-button v-if="variable.is_editable" class="customButton" type="primary" @click="fetchRecord(variable)"><i class="bi bi-pencil"></i></el-button>
                        <el-popconfirm  v-if="variable.is_deletable" :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([variable.id])">
                            <template #reference>
                                <el-button class="customButton ms-1" type="danger"><i class="bi bi-trash"></i></el-button>
                            </template>
                        </el-popconfirm>
                     </div>
                </div>
                <div class="mb-0" v-if="variable.description && variable.description.trim().length">
                    <span class="fw-bold text-gray-600">{{ variable.description }}</span>
                </div>
                <div class="mt-2">
                    <div class="border border-solid border-gray-500 p-2 rounded text-gray-900">
                        {{ variable.val }}
                    </div>
                </div>
                <div class="separator mb-7 mt-7"></div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="kt_modal_variable" ref="variableModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="variableForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.system.variable.cols.code') }}</label>
                                <el-form-item prop="code" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.code" type="text" :disabled="form.updateStatus"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.system.variable.cols.val') }}</label>
                                <el-form-item prop="val" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.val" type="textarea" rows="3" maxlength="512"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.system.variable.cols.description') }}</label>
                                <el-form-item prop="description">
                                    <el-input v-model="form.data.description" type="textarea" rows="3" maxlength="512"/>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    data(){
        return {
            tabSkeleton: true,
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {}
            },
        }
    },
    computed: {
        table() {
            return this.$store.state.system.variable.table.data;
        },
    },
    mounted() {
        this.loadSystemVariables();
    },
    methods: {
        loadSystemVariables(){
            this.$store.dispatch('system/variable/get', {}).then(() => {
                this.tabSkeleton = false;
            });
        },
        newRecord(){
            this.form.updateStatus = false;
            this.form.data = {};
            this.form.title = this.$t("pages.system.variable.new");
            this.showModal(this.$refs.variableModal);
        },
        fetchRecord(record) {
            this.form.updateStatus = true;
            this.form.title = this.$t("pages.system.variable.edit");
            this.axios.get(this.endpoints['system_variable']+ '/' + record.id).then(response => {
                this.form.data = response.data.data;
                this.showModal(this.$refs.variableModal);
            });
        },
        onSubmit(){
            this.$refs.variableForm.validate((valid) => {
                if(valid) {
                    this.form.loading = true;

                    if(this.form.data.id) {
                        this.axios.put(this.endpoints['system_variable'] + '/' + this.form.data.id, this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.loadSystemVariables();
                                this.hideModal(this.$refs.variableModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }else {
                        this.axios.post(this.endpoints['system_variable'], this.form.data).then(response => {
                            this.onResponse(response.data, () => {
                                this.loadSystemVariables();
                                this.hideModal(this.$refs.variableModal);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        deleteRecord(id){
            this.$store.dispatch("system/variable/delete", {
                id: id
            });
        },
    }
}
</script>

<style>
.customButton {
    padding: 6px 9px;
    min-height: unset !important;
}
</style>